<template>
  <div class="min-h-screen flex flex-col bg-gray-50 dark:bg-slate-900">
    <!-- Header -->
    <header class="w-full h-[60px] bg-white dark:bg-slate-800 p-4 shadow-md flex justify-between items-center">
      <div class="flex">
        <a href="/" class="flex h-full">
          <img class="h-8 w-auto mr-2" src="@/assets/images/logo_MDM_dark.png" alt="" />
          <span class="text-2xl font-bold dark:text-white">My Digital Mind</span>
        </a>
      </div>
      <button @click="toggleMenu" class="lg:hidden text-gray-700 dark:text-gray-300">
        <ion-icon name="menu-outline"></ion-icon>
      </button>
    </header>

    <div class="flex flex-1">
      <!-- Sidebar -->
      <aside :class="{'block': isMenuOpen, 'hidden': !isMenuOpen, 'lg:block': true}" class="w-64 bg-white dark:bg-slate-800 p-4 border-r dark:border-slate-700">
        <nav class="space-y-4">
          <a href="#" @click.prevent="navigate('home')" class="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
            <i class="icon-home"></i>
            <span>My Digital Mind Home</span>
          </a>
          <a href="#" @click.prevent="navigate('oauth')" class="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
            <i class="icon-lock"></i>
            <span>OAuth Integration</span>
          </a>
          <a href="#" @click.prevent="navigate('privacy')" class="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
            <i class="icon-privacy"></i>
            <span>Privacy Topics</span>
          </a>
          <a href="#" @click.prevent="navigate('data')" class="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
            <i class="icon-data"></i>
            <span>Use of Data</span>
          </a>
          <a href="#" @click.prevent="navigate('terms')" class="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
            <i class="icon-terms"></i>
            <span>Terms and Conditions</span>
          </a>
        </nav>
      </aside>


      <main class="flex-1 bg-gray-50 dark:bg-slate-900 p-8">
        <!-- Section 1: My Digital Mind Home -->
        <section v-if="section === 'home'">
          <h1 class="text-3xl font-bold text-gray-800 dark:text-white">My Digital Mind</h1>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            Bienvenido a My Digital Mind, un asistente virtual capaz de responder preguntas, guiarte y mejorar tu experiencia en diferentes áreas. Nuestro objetivo es ofrecerte soluciones rápidas, inteligentes y personalizadas para tus consultas diarias.
          </p>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            My Digital Mind está diseñado para ayudarte a navegar por el mundo digital de forma eficiente, ofreciéndote asistencia en temas que van desde la integración de OAuth hasta la planificación de viajes y servicios de restaurantes. 
          </p>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            Al combinar la inteligencia artificial con un enfoque centrado en el usuario, buscamos ofrecerte una experiencia única y mejorar tu productividad al brindarte respuestas claras y orientadas a la acción.
          </p>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            Ya sea que necesites ayuda con la privacidad de tus datos, aprender sobre las condiciones de uso o gestionar tus integraciones de OAuth, My Digital Mind está aquí para ayudarte.
          </p>
        </section>

        <!-- Section 2: OAuth Integration -->
        <section v-if="section === 'oauth'">
          <h1 class="text-2xl font-bold text-gray-800 dark:text-white">OAuth Integration</h1>
          <p class="text-gray-700 dark:text-gray-300 mt-4">
            My Digital Mind utiliza Google OAuth para permitir a los usuarios iniciar sesión de forma segura utilizando sus cuentas de Google. Su privacidad es nuestra prioridad y garantizamos un tratamiento seguro de sus datos.
          </p>
        </section>

        <!-- Section 3: Privacy Topics -->
        <section v-if="section === 'privacy'">
          <h1 class="text-2xl font-bold text-gray-800 dark:text-white">Política de Privacidad</h1>
          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Introducción</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">
            En MY DIGITAL MIND, nos comprometemos a proteger su privacidad y sus datos personales. Este Aviso de Privacidad describe cómo recopilamos, utilizamos y protegemos su información cuando utiliza nuestra aplicación y servicios asociados con OAuth de Google.
          </p>
          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Información que Recopilamos</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">Al utilizar nuestra aplicación con OAuth de Google, podemos recopilar la siguiente información:</p>
          <ul class="list-disc list-inside ml-6 text-gray-600 dark:text-gray-400">
            <li><strong>Permisos no sensibles:</strong>
              <ul class="list-disc list-inside ml-8 text-gray-600 dark:text-gray-400">
                <li><code class="text-purple-700 dark:text-purple-400">../auth/userinfo.email</code>: Ver su dirección de correo electrónico principal de la cuenta de Google.</li>
                <li><code class="text-purple-700 dark:text-purple-400">../auth/userinfo.profile</code>: Ver su información personal, incluyendo cualquier información personal que haya hecho pública.</li>
              </ul>
            </li>
            <li><strong>Permisos sensibles:</strong>
              <ul class="list-disc list-inside ml-8 text-gray-600 dark:text-gray-400">
                <li><code class="text-purple-700 dark:text-purple-400">../auth/gmail.send</code>: Permite enviar correos electrónicos en su nombre.</li>
                <li><code class="text-purple-700 dark:text-purple-400">../auth/calendar</code>: Ver, editar, compartir y borrar permanentemente todos los calendarios a los que puede acceder mediante el Calendario de Google.</li>
              </ul>
            </li>
          </ul>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Cómo Utilizamos su Información</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">La información que recopilamos a través de OAuth de Google se utiliza para los siguientes fines:</p>
          <ul class="list-disc list-inside ml-6 text-gray-600 dark:text-gray-400">
            <li><strong>Proveer y Mejorar nuestros Servicios:</strong> Utilizamos su información para autenticar su identidad y brindarle acceso a nuestras funciones y servicios.</li>
            <li><strong>Personalización:</strong> Personalizamos su experiencia en nuestra aplicación basándonos en su información de perfil.</li>
            <li><strong>Comunicación:</strong> Podemos enviarle notificaciones, actualizaciones y comunicaciones relacionadas con nuestro servicio.</li>
            <li><strong>Análisis:</strong> Utilizamos datos de uso para analizar y mejorar la eficiencia y la experiencia del usuario en nuestra aplicación.</li>
          </ul>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Compartición de Información</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">No compartimos su información personal con terceros, excepto en los siguientes casos:</p>
          <ul class="list-disc list-inside ml-6 text-gray-600 dark:text-gray-400">
            <li><strong>Con su Consentimiento:</strong> Podemos compartir su información con terceros si usted ha dado su consentimiento explícito.</li>
            <li><strong>Cumplimiento Legal:</strong> Podemos divulgar su información si es requerido por la ley o en respuesta a una solicitud válida de autoridades gubernamentales.</li>
            <li><strong>Proveedores de Servicio:</strong> Podemos compartir información con proveedores de servicio que nos ayudan a operar y mejorar nuestra aplicación.</li>
          </ul>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Seguridad de su Información</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">
            Nos comprometemos a proteger su información y utilizamos diversas medidas de seguridad para asegurar la integridad y confidencialidad de sus datos. Sin embargo, no podemos garantizar la seguridad absoluta de la información transmitida a través de internet.
          </p>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Sus Derechos</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">
            Usted tiene derecho a acceder o eliminar su información personal en cualquier momento. Para ejercer estos derechos, por favor contáctenos directamente a través de <a href="mailto:tony@mydigitalmind.ia" class="text-blue-600 dark:text-blue-400">tony@mydigitalmind.ia</a>.
          </p>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Cambios a este Aviso de Privacidad</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">
            Nos reservamos el derecho de modificar este Aviso de Privacidad en cualquier momento. Le notificaremos sobre cualquier cambio publicando el nuevo Aviso de Privacidad en nuestra aplicación y actualizando la fecha de "Última actualización" en la parte superior de este documento.
          </p>

          <h2 class="text-xl font-semibold text-gray-800 dark:text-white mt-4">Contacto</h2>
          <p class="text-gray-700 dark:text-gray-300 mt-2">
            Si tiene alguna pregunta o inquietud sobre este Aviso de Privacidad, por favor contáctenos a través de <a href="mailto:tony@mydigitalmind.ia" class="text-blue-600 dark:text-blue-400">tony@mydigitalmind.ia</a> o visítenos en nuestra dirección física: CDMX, México.
          </p>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      section: 'home',
      isMenuOpen: false,
    };
  },
  methods: {
    navigate(section) {
      this.section = section;
      this.isMenuOpen = false; // Close menu after navigation
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen; // Toggle menu visibility
    },
  },
};
</script>