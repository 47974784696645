<template>
    <footer class="bg-MDM-100 text-white dark:bg-MDM-900 py-8">
      <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-6">
        <!-- Logo y nombre -->
        <div class="flex items-center gap-4">
          <img
            src="@/assets/images/logo_MDM_dark.png"
            alt="My Digital Mind Logo"
            class="h-12 w-auto"
          />
          <span class="text-xl font-semibold tracking-wide">My Digital Mind</span>
        </div>
  
        <!-- Enlaces principales -->
        <div class="grid grid-cols-2 gap-6">
          <div>
            <h3 class="text-lg font-bold mb-2">Más información</h3>
            <ul class="space-y-2">
              <li>
                <a
                  href="/contacto"
                  class="text-gray-300 hover:text-MDM-300 transition duration-300"
                >
                  Contáctenos
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-lg font-bold mb-2">Acerca de</h3>
            <ul class="space-y-2">
              <li>
                <a
                  href="https://mydigitalmind.ai/privacy"
                  class="text-gray-300 hover:text-MDM-300 transition duration-300"
                >
                  Política de Privacidad
                </a>
              </li>
              <li>
                <a
                  href="/#"
                  class="text-gray-300 hover:text-MDM-300 transition duration-300"
                >
                  Términos y Condiciones
                </a>
              </li>
            </ul>
          </div>
        </div>
  
        <!-- Créditos -->
        <div class="flex flex-col items-start md:items-end">
          <p class="text-gray-300 text-sm">
            © 2024 My Digital Mind. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  </template>
  