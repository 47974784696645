<template>
  <div class="min-h-screen flex flex-col bg-gradient-to-b from-white to-MDM-100 dark:from-slate-800 dark:to-MDM-900">
    <!-- Header y Navbar -->
    <Header :isMenuOpen="isMenuOpen" @toggle-menu="toggleMenu" ></Header>

    <!-- Contenido principal -->
    <main class="mt-16 w-full px-6">
      <div class="bg-gray-100 dark:bg-gray-900 p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
        <h1 class="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100 text-center">
          Directorio de My Digital Mind
        </h1>
        <ul class="list-none">
          <!-- Asistente General -->
          <li class="mb-8">
            <a href="https://mydigitalmind.ai/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
              <ion-icon name="home-outline" class="mr-2"></ion-icon>
              Asistente General
            </a>
            <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">
              Interactúa con un asistente virtual diseñado para guiarte y responder a tus necesidades.
            </p>
          </li>

          <!-- Solei Tours -->
          <li class="mb-8">
            <a href="https://mydigitalmind.ai/developer/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
              <ion-icon name="briefcase-outline" class="mr-2"></ion-icon>
              Solei Tours (Agencia de Viajes)
            </a>
            <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">
              Gestión de viajes con opciones de reserva y sugerencias personalizadas.
            </p>
            <div class="ml-8 mt-2">
              <a href="https://wa.me/12512415196?text=Hola!%20Me%20podrías%20dar%20más%20información." class="text-green-500 dark:text-green-400 hover:underline flex items-center">
                <ion-icon name="logo-whatsapp" class="mr-2"></ion-icon>
                Contactar por WhatsApp
              </a>
            </div>
          </li>

          <!-- Restaurante -->
          <li class="mb-8">
            <a href="https://mydigitalmind.ai/restaurante/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
              <ion-icon name="restaurant-outline" class="mr-2"></ion-icon>
              Restaurante
            </a>
            <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">
              Gestión eficiente de reservas, menús y pedidos en línea para restaurantes.
            </p>
          </li>

          <!-- Dentista -->
          <li class="mb-8">
            <a href="https://mydigitalmind.ai/dentista/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
              <ion-icon name="medkit-outline" class="mr-2"></ion-icon>
              Dentista
            </a>
            <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">
              Gestión de citas y expedientes médicos para clínicas dentales.
            </p>
            <div class="ml-8 mt-2">
              <a href="https://wa.me/15592151922?text=Hola!%20Me%20podrías%20dar%20más%20información." class="text-green-500 dark:text-green-400 hover:underline flex items-center">
                <ion-icon name="logo-whatsapp" class="mr-2"></ion-icon>
                Contactar por WhatsApp
              </a>
            </div>
          </li>
        </ul>
      </div>
    </main>

    <footer class="w-full text-center mt-12">
      <p class="text-sm text-slate-600 dark:text-slate-400">
        Consulta nuestra <a href="https://mydigitalmind.ai/privacy" class="text-blue-500 dark:text-blue-300 underline">Política de Privacidad</a>
      </p>
      <p class="text-sm">
        © 2024 My Digital Mind. Todos los derechos reservados.
      </p>
    </footer>

  </div>
</template>

<script>
import Header from "@/components/HeaderComponent.vue";

export default {
  name: "DirectoryLinks",
  components: { Header },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
