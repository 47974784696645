<template>
  <div class="min-h-screen flex flex-col items-center bg-gradient-to-b from-white to-MDM-100 dark:from-slate-800 dark:to-MDM-900">
    
     <!-- Header -->
     <Header :isMenuOpen="isMenuOpen" @toggle-menu="toggleMenu" ></Header>

    <main class="mt-16 w-full">
      <!-- Encabezado -->
      <header class="w-full text-center py-10">
        <h1 class="text-4xl sm:text-5xl font-extrabold font-montserrat text-slate-900 dark:text-white">
          My Digital Mind
        </h1>
        <p class="text-lg sm:text-xl font-medium text-slate-700 dark:text-slate-300 mt-4 px-6 max-w-3xl mx-auto">
          Tu asistente de inteligencia artificial conversacional diseñado para facilitar tus actividades cotidianas
          y ayudarte a administrar tareas. Gracias a su integración con diversas APIs, ofrece soluciones inteligentes y personalizadas.
        </p>
      </header>

      <!-- Imagen -->
      <section class="w-full flex justify-center mt-8">
        <img
          src="@/assets/images/personajes/Avatar MDM.svg"
          alt="My Digital Mind Avatar"
          class="w-64 h-auto"
        />
      </section>

      <!-- Botones de acción -->
      <section class="w-full my-8 flex flex-col sm:flex-row justify-center gap-4">
        <button
          class="transition ease-in-out delay-150 bg-gray-800 dark:bg-slate-950 hover:-translate-y-1 hover:scale-105 hover:bg-gray-900 duration-300 px-6 py-3 text-white text-lg font-medium tracking-tight rounded-lg"
          @click="loginWithGoogle"
        >
          Log in with Google
        </button>
        <button
          class="transition ease-in-out delay-150 bg-gray-300 dark:bg-slate-800 hover:-translate-y-1 hover:scale-105 hover:bg-gray-400 duration-300 px-6 py-3 text-neutral-700 dark:text-white text-lg font-medium tracking-tight rounded-lg"
          @click="getStarted"
        >
          Continuar como Invitado
        </button>
      </section>

      <section id="features" class="w-full bg-white dark:bg-slate-900 py-16">
        <div class="container mx-auto px-6">
          <h2 class="text-3xl font-bold text-center text-slate-900 dark:text-white mb-8">Características Principales</h2>
          <div class="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
            <div class="p-4 bg-gray-100 dark:bg-slate-800 rounded-lg">
              <h3 class="text-xl font-semibold text-slate-900 dark:text-white">Gestión de Correos</h3>
              <p class="text-slate-700 dark:text-slate-300 mt-2">
                Lee, responde y organiza tus correos electrónicos con facilidad, aprovechando la integración con Gmail.
              </p>
            </div>
            <div class="p-4 bg-gray-100 dark:bg-slate-800 rounded-lg">
              <h3 class="text-xl font-semibold text-slate-900 dark:text-white">Creación de Reuniones</h3>
              <p class="text-slate-700 dark:text-slate-300 mt-2">
                Programa reuniones de manera rápida y eficiente, sincronizándolas automáticamente con Google Calendar.
              </p>
            </div>
            <div class="p-4 bg-gray-100 dark:bg-slate-800 rounded-lg">
              <h3 class="text-xl font-semibold text-slate-900 dark:text-white">Recordatorios Inteligentes</h3>
              <p class="text-slate-700 dark:text-slate-300 mt-2">
                Configura recordatorios para tus tareas importantes y recibe alertas directamente en tus dispositivos conectados.
              </p>
            </div>
            <div class="p-4 bg-gray-100 dark:bg-slate-800 rounded-lg">
              <h3 class="text-xl font-semibold text-slate-900 dark:text-white">Análisis Contextual</h3>
              <p class="text-slate-700 dark:text-slate-300 mt-2">
                My Digital Mind analiza tus correos y reuniones para ofrecerte información clave, como resúmenes y puntos importantes.
              </p>
            </div>
            <div class="p-4 bg-gray-100 dark:bg-slate-800 rounded-lg">
              <h3 class="text-xl font-semibold text-slate-900 dark:text-white">Gestión Multidispositivo</h3>
              <p class="text-slate-700 dark:text-slate-300 mt-2">
                Accede a tu asistente desde el navegador, escritorio o móvil, sincronizado en tiempo real.
              </p>
            </div>
          </div>
        </div>
      </section>

       <!-- Preguntas Frecuentes -->
    <section id="faq" class="w-full bg-gray-100 dark:bg-slate-800 py-16">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center text-slate-900 dark:text-white mb-8">Preguntas Frecuentes</h2>
        <div class="grid gap-8">
          <div>
            <h3 class="text-xl font-semibold text-slate-900 dark:text-white">¿Qué es My Digital Mind?</h3>
            <p class="text-slate-700 dark:text-slate-300 mt-2">
              Es un asistente de inteligencia artificial diseñado para ayudarte con tareas cotidianas como correos, reuniones y recordatorios.
            </p>
          </div>
          <div>
            <h3 class="text-xl font-semibold text-slate-900 dark:text-white">¿Cómo se integra con Google?</h3>
            <p class="text-slate-700 dark:text-slate-300 mt-2">
              Se conecta con tu cuenta de Google para sincronizar Gmail, Google Calendar y Google Tasks.
            </p>
          </div>
        </div>
      </div>
    </section>


    </main>

    <!-- Pie de página -->
    <FooterComponent></FooterComponent>
    
  </div>
</template>

<script>
import Header from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "WelcomePage",
  components: { Header, FooterComponent },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    getStarted() {
      // Navegación a la página de invitado
      window.location.href = "https://mydigitalmind.ai/contacto";
    },
    loginWithGoogle() {
      // Redirige al login con Google
      window.location.href = "https://mydigitalmind.ai/api/login";
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

