<template>
    <nav class="bg-MDM-100 text-white dark:bg-MDM-900 shadow-md fixed w-full z-10">
      <div class="container mx-auto flex items-center justify-between py-4 px-6">
        <!-- Logo y título -->
        <a href="/" class="flex items-center gap-2">
          <img
            src="@/assets/images/logo_MDM_dark.png"
            alt="My Digital Mind Logo"
            class="h-8 w-auto"
          />
          <span class="text-xl font-semibold tracking-wide">My Digital Mind</span>
        </a>
  
        <!-- Menú de navegación -->
        <div class="hidden md:flex gap-6 items-center">
          <a href="/#" class="hover:text-MDM-300 transition duration-300">Funciones</a>
          <a href="/directorio" class="hover:text-MDM-300 transition duration-300">Directorio</a>
          <a href="/#" class="hover:text-MDM-300 transition duration-300">Recursos</a>
        </div>
        <a
          href="/contacto"
          class="hidden md:inline-block bg-gray-300 text-gray-800 dark:bg-MDM-800 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-MDM-700 transition duration-300"
        >
          Probar Ahora
        </a>
  
        <!-- Botón Menú Responsive -->
        <button
          class="md:hidden text-gray-300 hover:text-white focus:outline-none focus:text-white"
          @click="$emit('toggle-menu')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
  
      <!-- Menú Responsive -->
      <div v-if="isMenuOpen" class="md:hidden bg-MDM-100 dark:bg-MDM-900 py-4 px-6">
        <a href="/#" class="block py-2 hover:text-MDM-300">Funciones</a>
        <a href="/directorio" class="block py-2 hover:text-MDM-300">Directorio</a>
        <a href="/#" class="block py-2 hover:text-MDM-300">Recursos</a>
        <a
          href="/contacto"
          class="block py-2 mt-4 bg-gray-300 text-gray-800 dark:bg-MDM-800 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-400 dark:hover:bg-MDM-700 transition duration-300"
        >
          Probar Ahora
        </a>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    props: {
      isMenuOpen: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  

  